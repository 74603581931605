(function () {

  // -- Constructor --
  var H = function (scope, station, site, opts) {

    this.audio = new Audio();
    this.container = scope;
    this.station_url = station;
    this.site_url = site;

    this.playing = false;
    this.play_on_load = false;
    this.current_id = null;
    this.last_loaded = null;
    this.playlist = new Array();
    this.ctrls = {};

    var controls = [
      "name",
      "subname",
      "time",
      "play",
      "image",
      "image-bg",
      "pause",
      "toggle",
      "next",
      "seek-range",
      "volume",
      "volume-range",
      "deezer-link",
      "itunes-link",
      "share-tw",
      "share-fb",
      "share-wa",
      "share-buttons",
      "like",
      "dislike"
    ];

    var defaults = {

      high_quality: true,
      next_path: "/schedule/up_next",
      image_size: "200x200",
      preload_size: 4,
      no_preload: false,
      on_play: function () {},
      on_time_change: function () {},
      before_audio_change: function () {},
      on_audio_change: function () {},
      on_change: function () {}
    };

    // Extend options, binding functions to 'this'
    this.opts = opts || {};
    for (var i in defaults)
      if (typeof this.opts[i] == "undefined")
        this.opts[i] = defaults[i];
      else if (typeof this.opts[i] == "function")
        this.opts[i] = this.opts[i].bind(this);

    // Get controls that appear in dom under scope
    controls.forEach(function (control) {
      var dom_control = this.container.querySelector("[data-"+control+"]");
      if (dom_control) {
        var valid_name = control.replace("-", "_");
        this.ctrls[valid_name] = dom_control;
      }
    }.bind(this));
  };

  // -- Player Functions --

  H.prototype.play = function () {

    this.opts.on_play();
    this.audio.play();
  };

  H.prototype.pause = function () {

    this.audio.pause();
  };

  H.prototype.toggle = function () {

    this.playing ? this.pause() : this.play();
  };

  H.prototype.is_paused = function () {

    return this.audio.paused;
  };

  H.prototype.next = function () {

    this.load_next();
  };

  H.prototype.interaction = function (kind, interacted_with_schedule) {

    var params = { s: this.current_id, k: kind };
    var tid = Cookies.get("maxwell_tid");
    if (interacted_with_schedule) params.s = this.current_id;
    if (typeof tid !== "undefined") params.tid = tid;
    return this.request(this.station_url+"/interactions", params, "POST").then(function (response) {

      var data = JSON.parse(response);
      console.log(data)
      if (data.status === 1 && data.tid) {
        Cookies.set("maxwell_tid", data.tid);
        console.log(data.tid);
      }
      return data;

    }).catch(function (error) {

      console.log("Hertz: Error on "+kind+" hit");
      throw error;

    });
  };

  H.prototype.vote = function (type) {

    var button = (type === "like") ? this.ctrls.like : this.ctrls.dislike;
    if (button) button.disabled = true;

    this.interaction((type === "like") ? "liked" : "disliked", true).then(function (data) {

      var disable = (data.status === 1);
      if (this.ctrls.like)
        this.ctrls.like.disabled = disable;
      if (this.ctrls.dislike)
        this.ctrls.dislike.disabled = disable;
      if (button)
        button.classList.add("hertz-used");

    }.bind(this)).catch(function (error) {

      console.log("Hertz: Error on vote -> "+error);
      if (button)
        button.disabled = false;

    });
  };

  H.prototype.like = function () {

    this.vote("like");
  };

  H.prototype.dislike = function () {

    this.vote("dislike");
  };

  H.prototype.skipped = function () {

    this.interaction("skipped");
  };

  H.prototype.listened = function () {

    this.interaction("listened");
  };

  H.prototype.shared = function () {

    this.interaction("shared");
  };

  H.prototype.get_audio_element = function () {

    return this.audio;
  };

  H.prototype.off_air = function () {

    alert("Off Air! :(");
  };

  H.prototype.loading = function () {

    this.container.classList.add("hertz-loading");

    for (key in this.ctrls) {
      var control = this.ctrls[key];
      if (control.nodeName === "BUTTON") {
        control.disabled = true;
        control.classList.add("hertz-disabled");
      }
    }
  };

  H.prototype.loaded = function () {

    for (key in this.ctrls) {
      var control = this.ctrls[key];
      if (control.nodeName === "BUTTON") {
        control.disabled = false;
        control.classList.remove("hertz-disabled");
        control.classList.remove("hertz-used");
      }
    }

    this.container.classList.remove("hertz-loading");
  };

  H.prototype.start = function () {

    this.interaction("visited");
    this.audio.crossOrigin = "use-credentials";
    this.set_audio_events();
    this.set_events();
    // Set fb share if control is set
    if (this.ctrls.share_fb) {
      var share_url = this.site_url+"?shared_id="+(this.shareable_id || "");
      this.ctrls.share_fb.onclick = function () {
        FB.ui({
          method: "share",
          display: "popup",
          href: share_url,
        }, function(response){});
      }
    }
    // Push the shared song if exists
    this.load_next(this.param("shared_id"));
  };

  // -- Events --

  H.prototype.set_events = function () {
    // Controls events
    var events = [
      ["play", "click", "play"],
      ["pause", "click", "pause"],
      ["toggle", "click", "toggle"],
      ["next", "click", "next_handler"],
      ["volume_button", "click", "toggle_volume"],
      ["volume", "input", "volume_handler"],
      ["seek", "input", "seek_handler"],
      ["like", "click", "like"],
      ["dislike", "click", "dislike"],
      ["share_fb", "click", "shared_handler"],
      ["share_tw", "click", "shared_handler"],
      ["share_wa", "click", "shared_handler"]
    ];

    events.forEach(function (event) {
      var control = this.ctrls[event[0]];
      if (control)
        control.addEventListener(event[1], this[event[2]].bind(this), false);
    }.bind(this));
  };

  H.prototype.set_audio_events = function () {
    // Audio related events
    var audio_events = [
      ["play", "play_handler"],
      ["pause", "pause_handler"],
      ["timeupdate", "timeupdate_handler"],
      ["ended", "ended_handler"]
    ];

    audio_events.forEach(function (event) {
      this.audio.addEventListener(event[0], this[event[1]].bind(this), false);
    }.bind(this));
  };

  // -- Event Handlers --

  H.prototype.play_handler = function (event) {

    this.playing = true;
    this.container.classList.remove("hertz-paused");
    this.container.classList.add("hertz-playing");
  };

  H.prototype.pause_handler = function (event) {

    this.playing = false;
    this.container.classList.add("hertz-paused");
    this.container.classList.remove("hertz-playing");
  };

  H.prototype.next_handler = function () {

    this.skipped();
    this.next();
  };

  H.prototype.ended_handler = function (event) {

    this.listened();
    this.next();
  };

  H.prototype.timeupdate_handler = function (event) {

    var time = this.audio.currentTime;
    if (this.ctrls.time)
      this.ctrls.time.textContent = this.duration_format(time);
    // Check changing to know if user is seeking the range slider
    if (!this.changing) {

      var percentage = time * (100 / this.audio.duration)
      // Update seek range slider
      if (this.ctrls.seek)
        this.ctrls.seek.value = percentage;
      // Fire time change event
      this.opts.on_time_change(time, percentage);
    }
  };

  H.prototype.shared_handler = function () {

    this.shared();
  };

  // -- Playlist Functions --

  H.prototype.change_audio = function (data) {

    if (this.opts.no_preload) {

      this.pause();
      this.audio.src = data.player_data.source;
      this.audio.crossOrigin = "use-credentials";
      this.audio.preload = true;
      this.audio.autoplay = true;
      this.audio.load();

    } else {

      var new_audio = data.preloader;
      this.pause();
      this.opts.before_audio_change(new_audio);
      delete this.audio;
      this.audio = new_audio;
      this.set_audio_events();
      this.opts.on_audio_change();
    }
  };

  H.prototype.push_schedule = function () {

    // Push as fetching before request to avoid
    // loading something that is already loading
    var schedule = {
      status: "fetching",
      onready: function () {},
      onerror: function () {}
    };
    this.playlist.push(schedule);
    return schedule;
  };

  H.prototype.push_audio = function (schedule, id) {

    // Set params
    var params = {};
    if (id)
      params = { shared_id: id }
    else if (this.last_loaded)
      params = { last_id: this.last_loaded }
    // Make request and return it's promise
    return this.request(this.station_url+this.opts.next_path, params).then(function (response) {

      var data = JSON.parse(response);

      if (data.status === 0) throw "off-air";

      if (!id) this.last_loaded = data.schedule_id;
      // var quality = this.opts.high_quality ? "_high" : "_low";
      // Preload audio
      // data.player_data.source = data.player_data.sources[(this.audio.canPlayType("audio/ogg") ? "ogg" : "mp3")+quality];
      data.player_data.source = data.player_data.sources["mp3"];

      if (!this.opts.no_preload) {

        var preloader = new Audio();
        preloader.crossOrigin = "use-credentials";
        preloader.preload = "true";
        preloader.src = data.player_data.source;
        preloader.load();
        data.preloader = preloader;
      }
      // Set schedule
      schedule.status = "ready";
      schedule.data = data;
      schedule.onready(data);

    }.bind(this)).catch(function (error) {

      schedule.status = "failed";
      console.log("Hertz: Failed to load audio "+error);
      // Die if the error was off air
      // to avoid an infinite loop
      if (error !== "off-air")
        schedule.onerror();
      throw error;

    }.bind(this));
  };

  H.prototype.fill_playlist = function (id) {

    var times = this.opts.preload_size - this.playlist.length;

    this.promise_times(times, function (i) {

      var schedule = this.push_schedule();
      return this.push_audio(schedule, (i === 0 ? id : null));

    }.bind(this));
  };

  H.prototype.load_next = function (id) {

    // Start loading block
    this.loading();
    this.fill_playlist(id);
    // Get next schedule
    var schedule = this.playlist.shift();

    var load = function (data) {

      this.current_id = data.schedule_id;
      var source = data.player_data.source;
      var pdata = data.player_data;
      // Update source (this changes our audio obj)
      var was_playing = !this.audio.paused;
      this.change_audio(data);
      // Update share links, except fb (it's set with the SDK)
      if (pdata.shareable === true) {
        var share_url = this.site_url+"?shared_id="+(this.current_id || "");
        this.ctrls.share_buttons.classList.remove("hertz-not-set");
        if (this.ctrls.share_tw) {
          // Twitter
          var tw_url = "https://twitter.com/intent/tweet?url="+share_url;
          this.ctrls.share_tw.href = tw_url;
        }
        if (this.ctrls.share_wa) {
          // Whatsapp
          var wa_url = "whatsapp://send?text="+share_url;
          this.ctrls.share_wa.href = wa_url;
        }
      } else {
        this.ctrls.share_buttons.classList.add("hertz-not-set");
      }
      // Set image size to the one on the options
      var image = pdata.image.replace("100x100", this.opts.image_size) || "";
      // Only set subname on songs
      if (pdata.type === "song") {
        var name = pdata.name || "";
        var subname = pdata.artist || "";
      } else {
        var name = data.station_name || "";
        var subname = "";
      }
      // Update view controls only if set
      if (this.ctrls.name) this.ctrls.name.textContent = name;
      if (this.ctrls.subname) this.ctrls.subname.textContent = subname;
      if (this.ctrls.image_bg) this.ctrls.image_bg.style.backgroundImage = "url("+image+")";
      if (this.ctrls.image) this.ctrls.image.src = image;
      // Listen on [service] links
      this.maybe_set_link(this.ctrls.deezer_link, pdata.deezer_link);
      this.maybe_set_link(this.ctrls.itunes_link, pdata.itunes_link);
      // End loading block
      this.loaded();
      if (was_playing) this.play();
      this.opts.on_change();

    }.bind(this);

    // Check the schedule status,
    // set as cb if not ready yet
    // just skip if it couldn't be loaded
    switch (schedule.status) {

      case "ready":
        load(schedule.data);
        break;
      case "fetching":
        schedule.onready = load;
        schedule.onerror = this.load_next.bind(this);
        break;
      case "failed":
        this.load_next();
        break;
    }
  };

  // -- Helper Functions --

  H.prototype.promise_times = function (times, action, time) {
    if (time >= times) return time;
    var time = time+1 || 0;
    return action(time).then(this.promise_times.bind(this, times, action, time));
  };

  H.prototype.maybe_set_link = function (anchor, value) {

    if (anchor) {
      if (value) {
        anchor.href = value;
        anchor.classList.remove("hertz-not-set");
      } else {
        anchor.href = "";
        anchor.classList.add("hertz-not-set");
      }
    }
  };

  H.prototype.param = function (name, url) {

    if (!url) url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
  };

  H.prototype.request = function (url, params, method) {

    return new Promise(function (resolve, reject) {

      method = method || "GET";

      var req = new XMLHttpRequest();

      if (!req) reject(Error("Hertz: Giving up :( Cannot create an XMLHTTP instance"));

      req.onreadystatechange = function () {

        if (req.readyState === XMLHttpRequest.DONE) {
          if (req.status === 200)
            resolve(req.responseText);
          else
            reject(Error(req.responseText));
        }
      };

      req.onerror = function () {
        reject(Error("Hertz: Network Error"));
      };

      var p = "";
      for (var name in params) {
        if (params[name]) {
          if (p != "") p += "&";
          p += (name + "=" + encodeURIComponent(params[name]));
        }
      }
      // if (method === "GET") {
        url += "?"+p;
        req.open(method, url);
        req.send();
      // } else {
      //   req.open(method, url);
      //   req.send(p);
      // }
    });
  };

  // Set as global object
  window.Hertz = H;

})();