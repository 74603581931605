(function () {

  // Fastclick eliminating the 300ms delay between a physical tap and the firing of a click event on mobile browsers.
  FastClick.attach(document.body);

  // Audio stuff for waveform
  var audio_ctx, analyser, source, bufferLength, freqs;

  /* Init player */
  var player_dom = document.getElementById("player");
  var player_progress = document.getElementById("player-progress");
  var player = new Hertz(player_dom, "https://admin.emergente.fm", "https://emergente.fm", {
    no_preload: (is_mobile.any && window.navigator.standalone != true),
    before_audio_change: function (audio) {
      if (!is_mobile.any) set_waveform(audio);
    },
    on_play: function () {
      if (audio_ctx) audio_ctx.resume();
    },
    on_time_change: function (time, percentage) {
      var scale = "scale("+percentage/100+", 1)";
      player_progress.style["WebkitTransform"] = scale;
      player_progress.style["MozTransform"] = scale;
      player_progress.style["msTransform"] = scale;
      player_progress.style["transform"] = scale;
    },
    on_change: function () {
      player_progress.classList.add("no-transitions");
      player_progress.offsetHeight[0];
      player_progress.style["WebkitTransform"] = "scale(0, 1)";
      player_progress.style["MozTransform"] = "scale(0, 1)";
      player_progress.style["msTransform"] = "scale(0, 1)";
      player_progress.style["transform"] = "scale(0, 1)";
      player_progress.offsetHeight[0];
      player_progress.classList.remove("no-transitions");
    }
  });

  if (is_mobile.phone)
    document.body.classList.add("phone");

  if (!is_mobile.any) {

    /* Waveform */
    var WIDTH, HEIGHT;

    // Audio stuff
    audio_ctx = new (window.AudioContext || window.webkitAudioContext)();
    analyser = audio_ctx.createAnalyser();

    // Update measures now and on resize
    resize_handler();
    window.addEventListener("resize", resize_handler, false);

    // Canvas stuff
    var canvas = document.getElementById("waveform-canvas");
    var ctx = canvas.getContext("2d");
    paper.setup(canvas);

    set_waveform(player.get_audio_element());

    // Waveform path initialization
    var path = new paper.Path();
    path.strokeColor = "#D21524";
    path.fillColor = "#FF3333";

    var slice_width = WIDTH * 1.0 / (dots-1);
    var dots = 30;
    var start = 20;
    var end = 80;

    // Waveform initial state
    path.add(new paper.Point(0, HEIGHT));
    for (var i = 0; i < dots; i++)
      path.add(new paper.Point(i*slice_width, HEIGHT/2));
    path.add(new paper.Point(WIDTH, HEIGHT));
    var reduced_dots;

    // Animation function
    paper.view.onFrame = function (event) {

      // Retrieve 128 array of frequency in this moment
      analyser.getByteFrequencyData(freqs);
      freqs_arr = [].slice.call(freqs);
      // Reduce data averages the data in the hole array to have
      // only as many info as dots (also it exagerates data a little bit).
      // The algorithm loads not fitting data to the left, and the freqs
      // are loaded to the left also (mostly), so we reverse previously
      // and after the process to aliviate this issue.
      if (!player.is_paused() || typeof reduced_dots === "undefined")
        reduced_dots = reduce_data(freqs_arr.slice(start, end).reverse(), dots).reverse();
      // Recalculate the slice size, as WIDTH could've changed
      slice_width = WIDTH * 1.0 / (dots-1);
      // Move every dot in the waveform path
      for (var i = 0; i < dots; i++) {
        var segment = path.segments[i+1];
        var v = reduced_dots[i] / 255.0;
        var y = HEIGHT - (v * HEIGHT/2);
        segment.point = new paper.Point(i*slice_width, y)
      }
      path.segments[0].point.y = HEIGHT;
      path.segments[dots+1].point = new paper.Point(WIDTH, HEIGHT);
      path.closed = true;
      path.smooth({ from: 1, to: dots });
    };

  }

  player.start();

  function set_waveform (audio) {

    source = audio_ctx.createMediaElementSource(audio);

    source.connect(analyser);
    analyser.connect(audio_ctx.destination);

    // Analyser init
    analyser.fftSize = 256;
    bufferLength = analyser.frequencyBinCount;
    freqs = new Uint8Array(bufferLength);
  }

  // Handlers
  function resize_handler () {

    WIDTH = window.innerWidth;
    HEIGHT = window.innerHeight;
  }

  /* Util functions */

  function average (a, b) {
    return (a + b)/1.6;
  }

  function reduce_data (arr, n) {

    var rest = arr.length % n; // how much to divide
    var rest_used = rest; // to keep track of the division over the elements
    var part_length = Math.floor(arr.length / n);
    var result = [];

    for (var i = 0; i < arr.length; i += part_length) {
      var end = part_length + i;
      var add = false;
      if (rest !== 0 && rest_used) { // should add one element for the division
        end++;
        rest_used--; // we've used one division element now
        add = true;
      }
      result.push(arr.slice(i, end).reduce(average, 0)); // part of the array
      if (add) i++; // also increment i in the case we added an extra element for division
    }
    return result;
  }

})();